<template>
    <div class="saleBack">
        <div class="sale">
        <p class="sale__title">Наши аквапарки</p>
        <div class="sale__block">
            <div class="sale__block__left">
            <p>Hawaii</p>
            <img src="@/assets/images/sale1.jpg"/>
        </div>
        <div class="sale__block__right">
            <p>San-Remo</p>
            <img src="@/assets/images/sale2.jpg"/>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.saleBack{
    background: linear-gradient(45deg,blue, rgb(102, 255, 0));
    width: 100%;
    min-height: 100vh;
}
    .sale{
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 90%;
        padding-top: 100px;
        margin: 0 auto;
        @media(max-width:768px){
            padding-top: 50px;
            gap: 30px;
        }
        &__title{
            text-align: center;
            font-size: 54px;
            color: white;
            @media(max-width:768px){
                font-size: 36px;
            }
        }
        &__block{
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media(max-width:768px){
                flex-direction: column;
                gap: 30px;   
            }
        &__left{
            width: 49%;
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 30px;
            aspect-ratio: 1.5;
            p {
                color: white;
                font-size: 36px;
            }

                img{
                    width: 100%;
                    aspect-ratio: 1.5;
                    border-radius: 50px;
                    object-fit: cover;
                }
                
            @media(max-width:768px){
                width: 100%;
                p{
                    
                font-size: 24px;
                }
            }
        }
        &__right{
            width: 49%;align-items: center;
            display: flex;
            flex-direction: column;
            gap: 30px;
            p {
                color: white;
                font-size: 36px;
            }
            img{
                width: 100%;
                aspect-ratio: 1.5;
                border-radius: 50px;
                object-fit: cover;
            }
            @media(max-width:768px){
                width: 100%;
                p{
                    font-size: 24px;
                }
            }
        }
        }
    }
</style>